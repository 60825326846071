import Vue from 'vue';
import Vuex from 'vuex';
import request from '@/utils/request';


const state = {
  classifyVal:'', //菜单值产品中心商品分类值
  code: [], //二维码
  aboutIndex:0,  //关于我们菜单下标
  joinInvestmentIndex:0,  //招商加盟下标
  serviceSupportIndex:0, //服务支持
  ronrunNewsIndex:0, //融润资讯
  engineeringIndex:0,  //国内外下标
};

const actions = {
  getCode({ commit }, data) {
    return request({
      url: `/api/qrcode`,
      method: 'get',
      data: data
    }).then(res => {
      commit('setCode', res.data)
    })
  },
  // getAboutIndex({ commit }, data) {
  //   commit('setAboutIndex', data)
  // },
  // getJoinInvestmentIndex({ commit }, data) {
  //   commit('setJoinInvestmentIndex', data)
  // },
  // getEngineeringIndex({ commit }, data) {
  //   commit('setEngineeringIndex', data)
  // },
};

const mutations = {
  setClassifyVal(state, provider){
    state.classifyVal = provider
  },
  setCode(state, provider) {
    state.code =  provider
  },
  setAboutIndex(state, provider){
    state.aboutIndex = provider
  },
  setServiceSupportIndex(state, provider){
    state.serviceSupportIndex = provider
  },
  setRonrunNewsIndex(state, provider){
    state.ronrunNewsIndex = provider
  },
  setJoinInvestmentIndex(state, provider){
    state.joinInvestmentIndex = provider
  },
  setEngineeringIndex(state, provider){
    state.engineeringIndex = provider
  },

};



Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  mutations,
  state,
})