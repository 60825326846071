<template>
    <div id="footer">
        <div class="footer">
            <div class="logo-menu">
                <div class="logo">
                    <!-- <img src="@/assets/logo2.png" alt="" srcset="" @click="$router.push('/HomeView')"> -->
                </div>
                <!-- <div class="code-list">
                    <div class="item">
                        <img src="@/assets/images/common/xhs.png" alt="" srcset="">
                        <div class="itemCode" v-if="code[0]">
                            <img :src="code[0].img" alt="">
                        </div>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/common/wx.png" alt="" srcset="">
                        <div class="itemCode" v-if="code[1]">
                            <img :src="code[1].img" alt="">
                        </div>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/common/dy.png" alt="" srcset="">
                        <div class="itemCode" v-if="code[2]">
                            <img :src="code[2].img" alt="">
                        </div>
                    </div>
                </div> -->
                <div class="phone">
                    <div class="item">
                        <span class="text">全国服务热线</span><br>
                        <span class="phoneNumber">400-000-6438</span>
                    </div>
                    <div class="item">
                        <span class="text">招商财富热线</span><br>
                        <span class="phoneNumber">189 2227 0085</span>
                    </div>
                </div>
            </div>
            <div class="lineBottom"></div>
            <div class="website">
                Copyright © 2019 广江苏恒昌铸造科技有限公司. All Rights Reserved.隐私声明粤公网安备<span class="beian"
                    @click="goWin">粤ICP备20058269号-6</span>
            </div>
        </div>
    </div>
</template>

<script>
import { getRecommend } from "@/api/home";
import { mapState, mapMutations } from "vuex";
export default {
    name: 'Footer',
    data() {
        return {
            qrCode: [],
            footIndex: 0,
        }
    },
    computed: mapState(['code']),
    mounted() {
    },
    methods: {
        goWin() {
            let url = 'https://beian.miit.gov.cn/'
            window.open(url, '_blank');
        }
    }
}
</script>

<style lang="scss" scoped>
.footer {
    padding-top: 20px;
    background: #002d73;
    color: #fff;
    font-size: 14px;

    .logo-menu {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
            cursor: pointer;

            img {
                width: 148px;

            }
        }

        .phone {
            .item {
                margin-top: 12px;

                .text {
                    line-height: 35px;
                }

                .phoneNumber {
                    color: #b02930;
                    font-weight: 700;
                    font-size: 30px;
                }
            }
        }



        .code-list {
            display: flex;
            align-items: center;
            margin-top: 20px;

            .item {
                position: relative;
                margin-right: 39px;
                cursor: pointer;

                .itemCode {
                    display: none;
                    transition: all .5s;
                    position: absolute;
                    bottom: 72px;
                    left: 50%;
                    z-index: 2;
                    transform: translateX(-50%);
                    background: #fff;
                    padding: 2px;
                    border-radius: 2px;

                    img {
                        width: 143px;
                        height: 143px;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        transform: translate(-50%, 100%);
                        width: 0;
                        height: 0;
                        border-width: 20px 15px 20px 15px;
                        border-style: solid;
                        border-color: #fff transparent transparent transparent;
                    }
                }

                &:hover {
                    .itemCode {
                        display: block;
                    }
                }
            }

        }
    }


    .lineBottom {
        width: 100%;
        height: 1px;
        background: #454545;
        margin-top: 2px;
        margin-bottom: 8px;
    }

    .website {
        color: #acacac;
        padding: 0 10px;
        padding-bottom: 10px;
    }
}
</style>
