<template>
    <div id="header">
        <div class="goTopModule" :class="{ 'isGoTop': isShowTool }" v-show="isShowTool" @click="goTop">
            <div class="goTop">
                <i class="el-icon-top"></i>
            </div>
            <div class="goTopTip">BACK Top</div>
        </div>
        <div class="headerTool">
            <div class="wBody headerToolCon" v-if="false">
                <div class="item" @click="goUrl(1)">
                    <img src="@/assets/images/common/header1.png" alt="" srcset="">
                    <span>融润商城</span>
                </div>
                <div class="item" @click="goUrl(2)">
                    <img src="@/assets/images/common/header2.png" alt="" srcset="">
                    <span>经销商登录</span>
                </div>
                <div class="item" @click="goUrl(3)">
                    <img src="@/assets/images/common/header3.png" alt="" srcset="">
                    <span>会员中心</span>
                </div>
            </div>
        </div>
        <div class="header">
            <div class="wBody">
                <div class="logo">
                    <img src="@/assets/logo.png" alt="" srcset="" @click="$router.push('/HomeView')">
                </div>
                <ul class="menu">
                    <li class="menuItem" v-for="(item, index) in navList" :key="index"
                        @click="parentClick(index, item.url)">
                        <span class="menuText">{{ item.name }}</span>
                        <ul class="menuChild" v-show="item.childList" @click.stop>
                            <li class="menuChildClick" v-for="(itemChild, indexChild) in item.childList" :key="indexChild"
                                @click.stop="childClick(index, indexChild, itemChild.url)">
                                <div class="menuChildItem">
                                    <span>{{ itemChild.name }}</span>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <!-- <div class="bannerCommon swiper_warp">
            <div class="swiper-container banner-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item, index) in bannerList" :key="index">
                        <img class="bannerCommon_img" :src="item.imageUrl" v-if="index==0||index==(item.length-1)">
                        <img class="bannerCommon_img" v-lazy="item.imageUrl" alt="" srcset="" v-else>
                    </div>
                </div>
                <div class="swiper-pagination banner-pagination"></div>
            </div>
        </div> -->
    </div>
</template>

<script>

import { mapState, mapMutations } from "vuex";
export default {
    name: 'Header',
    data() {
        return {
            activeIndex: -1,
            navList: [
                { name: '首页', url: '/HomeView', childList: [] },
                { name: '走进恒昌', url: '/AboutUs', childList: [{ name: '企业简介', url: '/AboutUs/companyIntroduction' }, { name: '人才招聘', url: '/AboutUs/talentRecruitment' }, { name: '联系我们', url: '/AboutUs/contractUs' }] },
                { name: '产品中心', url: '/product', childList: [] },
                { name: '技术实力', url: '/serviceSupport', childList: [{ name: '制造设备', url: '/serviceSupport/technicalStrength' }, { name: '加工设备', url: '/serviceSupport/technicalStrength' }, { name: '检测设备', url: '/serviceSupport/technicalStrength' }] },
                { name: '恒昌资讯', url: '/ronrunNews', childList: [{ name: '新闻中心', url: '/ronrunNews/newsCenter' }, { name: '企业视频', url: '/ronrunNews/brandVideo' }, { name: '招标信息', url: '/ronrunNews/ecology' }] },
                { name: '营销网络', url: '/engineering', childList: [{ name: '国际工程', url: '/engineering' }, { name: '国内工程', url: '/engineering' }] },
                { name: '企业愿景', url: '/joinInvestment', childList: [{ name: '发展目标', url: '/joinInvestment/brandStrength' }, { name: '近期目标', url: '/joinInvestment/brandStrength' }, { name: '中期目标', url: '/joinInvestment/brandStrength' }, { name: '远景目标', url: '/joinInvestment/brandStrength' }] }
            ],
            scrollTop: 0,
            isShowTool: false,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
    },
    destroyed() {
        window.removeEventListener('scroll', this.scrollToTop);
    },
    methods: {
        ...mapMutations(['setAboutIndex', 'setJoinInvestmentIndex', 'setServiceSupportIndex', 'setRonrunNewsIndex', 'setEngineeringIndex']),
        // 获取页面滚动距离
        handleScroll() {
            this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if (this.scrollTop > 500) {
                this.isShowTool = true
            } else {
                this.isShowTool = false
            }
        },
        goTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
            // document.documentElement.scrollTop = 0
        },
        goUrl(val) { // 1.商城 2.经销商 3.会员
            let url = val == 1 ? 'https://www.baidu.com' : val == 2 ? '' : ''
            //location.href = url  //直接跳转
            window.open(url, '_blank');  //新窗口打开
        },
        parentClick(index, url) {
            if (index == 1) {
                this.setAboutIndex(0)
            }

            if (index == 3) {
                this.setServiceSupportIndex(0)
            }
            if (index == 4) {
                this.setRonrunNewsIndex(0)
            }
            if (index == 5) {
                this.setEngineeringIndex(0)
            }
            if (index == 6) {
                this.setJoinInvestmentIndex(0)
            }
            this.$router.push(url)
        },
        childClick(index, index2, url) {
            if (index == 1) {
                this.setAboutIndex(index2)
            }

            if (index == 3) {
                this.setServiceSupportIndex(index2)
            }
            if (index == 4) {
                this.setRonrunNewsIndex(index2)
            }
            if (index == 5) {
                this.setEngineeringIndex(index2)
            }
            if (index == 6) {
                this.setJoinInvestmentIndex(index2)
            }
            this.$router.push(`${url}?index=` + index2)
        },
    }
}
</script>

<style lang="scss" scoped>
.isGoTop {
    animation: showOpacity 1s ease-in forwards;
}

@keyframes showOpacity {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}

.goTopModule {
    opacity: 0;
    position: fixed;
    right: 40px;
    bottom: 90px;
    width: 36px;
    height: 36px;
    z-index: 99;
    transition: all 2s;

    .goTop {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        inset: 0;
        width: 36px;
        height: 36px;
        background: #cecece;
        color: #fff;
        transition: all .5s;
        cursor: pointer;
        font-size: 26px;
        border-radius: 10px;
    }

    .goTopTip {
        position: absolute;
        font-family: "Roboto Condensed";
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        white-space: nowrap;
        left: 48%;
        bottom: 100%;
        transform: rotate(-90deg) translateX(-30px);
        transform-origin: 0 50%;
        transition: all .4s ease-in-out;
        opacity: 0;
        pointer-events: none;
        z-index: -1;
        letter-spacing: 2px;
        color: #000;
    }

    &:hover {
        .goTop {
            background: #000;
        }

        .goTopTip {
            opacity: 1;
            transform: rotate(-90deg) translateX(0);
        }
    }
}

.headerTool {
    background: #333333;
    height: 54px;

    .headerToolCon {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        padding-right: 26px;

        .item {
            display: flex;
            align-items: center;
            color: #fff;
            margin-left: 14px;
            font-size: 12px;
            cursor: pointer;

            img {
                margin-right: 10px;
                width: 24px;
                height: 24px;
            }
        }
    }

}

.header {
    height: 80px;

    .wBody {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            width: 354px;

            img {
                width: 354px;
                cursor: pointer;
            }
        }

        .menu {
            display: flex;
            align-items: center;
            height: 100%;

            .menuItem {
                position: relative;
                display: flex;
                align-items: center;
                padding: 0 24px;
                height: 100%;
                cursor: pointer;
                font-size: 14px;

                .menuChild {
                    display: none;
                    transition: all .5s;
                }

                .menuText {
                    display: flex;
                    align-items: center;
                    height: 100%;
                }

                &:hover {
                    .menuText {

                        color: #1e4c99;
                    }

                    .menuChild {
                        display: block;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 70px;
                        height: 2px;
                        background: #1e4c99;
                    }
                }
            }

            .menuChild {
                position: absolute;
                top: 80px;
                left: 0;
                right: 0;
                height: auto;
                z-index: 9;
                background: #fff;

                .menuChildClick {
                    padding: 0 20px;

                    .menuChildItem {
                        padding: 11px 0;
                        text-align: center;
                        border-bottom: 1px solid #eee;

                        &:first-child {
                            padding-top: 18px;
                        }

                        &:last-child {
                            padding-bottom: 15px;
                        }

                        &:hover {
                            color: #b02930;

                        }
                    }
                }
            }

        }
    }

}


.navChild {}

@keyframes childHeight {
    0% {
        opacity: 0;
    }

    100% {
        opacity: auto;
    }
}
</style>
