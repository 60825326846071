<template>
  <div id="app" class="isPc">
    <HeaderPC v-if="header_show&&!isMobile" />
    <HeaderMobile v-if="header_show&&isMobile" />
    <router-view class="main_warp" @public_header="public_header" @public_footer="public_footer" />
    <FooterPC v-if="footer_show&&!isMobile" />
    <FooterMobile v-if="footer_show&&isMobile" />
  </div>
</template>
<script>
import HeaderPC from '@/components/HeaderPC';
import FooterPC from '@/components/FooterPC';
import HeaderMobile from '@/components/HeaderMobile';
import FooterMobile from '@/components/FooterMobile';

export default {
  components: {
    HeaderPC,
    FooterPC,
    HeaderMobile,
    FooterMobile
  },
  name: 'App',
  created() {
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    this.isMobile = flag ? true : false
    console.log(this.isMobile);
  },
  data() {
    return {
      header_show: true,
      footer_show: true,
      isMobile: false
    }
  },
  watch: {
    '$route'(to, from) {
      this.activeIndex = to.path;
      /** 纯pc */
      if (this.activeIndex == '/login') {
        this.header_show = false;
        this.footer_show = false;
      } else {
        this.header_show = true;
        this.footer_show = true;
      }
    }
  },
  mounted() {
    this.$store.dispatch('getCode')
    /** 自适应 */
    if (this.$route.path == '/login') {
      console.log("=================进来了22")
      this.header_show = false;
      this.footer_show = false;
    } else {
      this.header_show = true;
      this.footer_show = true;
    }
  },
  methods: {
    public_header(bool) {
      console.log("进来了===========")
      this.header_show = bool;
    },
    public_footer(bool) {
      this.footer_show = bool;
    }
  }
}
</script>
<style>
@import '@/assets/css/common.css';

#app {
  /* font-family: 'Microsoft YaHei'; */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  /* background-color: #42b983; */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


.main_warp {
  flex: 1;
}
</style>
