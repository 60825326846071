import HomeView from "../views/PC/HomeView.vue";
import AboutUs from "../views/PC/aboutUs/aboutUs.vue";
import joinInvestment from "../views/PC/joinInvestment/joinInvestment.vue";
import serviceSupport from "../views/PC/serviceSupport/serviceSupport.vue";
import ronrunNews from "../views/PC/ronrunNews/ronrunNews.vue";

const routes = [
  {
    path: "/",
    redirect: "/HomeView",
  },
  {
    path: "/HomeView",
    name: "HomeView",
    component: HomeView,
  },
  {
    path: "/AboutUs",
    name: "AboutUs",
    redirect: "/AboutUs/companyIntroduction",
    component: AboutUs,
    children: [
      {
        path: "companyIntroduction",
        component: () => import("@/views/PC/aboutUs/companyIntroduction"),
      },
      {
        path: "talentRecruitment",
        component: () => import("@/views/PC/aboutUs/talentRecruitment"),
      },
      {
        path: "contractUs",
        component: () => import("@/views/PC/aboutUs/contractUs"),
      },
    ],
  },
  {
    path: "/joinInvestment",
    name: "joinInvestment",
    redirect: "/joinInvestment/brandStrength",
    component: joinInvestment,
    children: [
      {
        path: "brandStrength",
        component: () => import("@/views/PC/joinInvestment/brandStrength"),
      },
    ],
  },
  {
    path: "/product",
    name: "product",
    component: () => import("@/views/PC/product/product"),
  },
  {
    path: "/productDetail",
    name: "productDetail",
    component: () => import("@/views/PC/product/productDetail"),
  },
  {
    path: "/serviceSupport",
    name: "serviceSupport",
    redirect: "/serviceSupport/technicalStrength",
    component: serviceSupport,
    children: [
      {
        path: "technicalStrength",
        component: () => import("@/views/PC/serviceSupport/technicalStrength"),
      },
    ],
  },
  {
    path: "/ronrunNews",
    name: "ronrunNews",
    redirect: "/ronrunNews/newsCenter",
    component: ronrunNews,
    children: [
      {
        path: "newsCenter",
        component: () => import("@/views/PC/ronrunNews/newsCenter"),
      },
      {
        path: "newsCenterDetail",
        component: () => import("@/views/PC/ronrunNews/newsCenterDetail"),
      },
      {
        path: "brandVideo",
        component: () => import("@/views/PC/ronrunNews/brandVideo"),
      },
      {
        path: "ecology",
        component: () => import("@/views/PC/ronrunNews/ecology"),
      },
    ],
  },
  {
    path: "/engineering",
    name: "engineering",
    component: () => import("@/views/PC/engineering/engineering"),
  },
];

export default routes;
