<template>
    <div>
        <div id="header" :class="whether ? 'isFixed' : ''">
            <div class="headerTool">
                <div class="headerToolCon" v-if="false">
                    <div class="item" @click="goUrl(1)">
                        <img src="@/assets/images/common/header1.png" alt="" srcset="">
                        <span>融润商城</span>
                    </div>
                    <div class="item" @click="goUrl(2)">
                        <img src="@/assets/images/common/header2.png" alt="" srcset="">
                        <span>经销商登录</span>
                    </div>
                    <div class="item" @click="goUrl(3)">
                        <img src="@/assets/images/common/header3.png" alt="" srcset="">
                        <span>会员中心</span>
                    </div>
                </div>
            </div>
            <div class="header">
                <div class="logo">
                    <img src="@/assets/logo.png" alt="" srcset="" @click="goIndex">
                </div>
                <div class="menu_icon" @click="isMenuShow = true">
                    <div class="line"></div>
                </div>
            </div>
            <div class="menu_box" :class="{ 'showBox': isMenuShow }" v-show="isMenuShow" @click="isMenuShow = false">
                <div class="menu_content" :class="{ 'showMenu': isMenuShow }" @click.stop>
                    <div class="close" @click="isMenuShow = false">
                        <i class="el-icon-close"></i>
                    </div>
                    <div class="logo" @click="goIndex">
                        <img src="@/assets/logo.png" alt="" srcset="">
                    </div>
                    <ul class="menu">
                        <li class="menuItem" v-for="(item, index) in navList" :key="index">
                            <div class="one" :class="{ 'oneCheck': menuIndex == index }" @click="menuClick(index)">
                                <span class="menuText">{{ item.name }}</span>
                                <i class="el-icon-arrow-down" v-if="index != 0 && index != 2"></i>
                            </div>
                            <ul class="menuChild" :class="{ 'menuChildShow': menuIndex == index }">
                                <li class="menuChildItem" v-for="(itemChild, indexChild) in item.childList"
                                    :key="indexChild" @click="childClick(index, indexChild, itemChild.url)">
                                    <span>{{ itemChild.name }}</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <div class="banner swiper_warp">
            <div class="swiper-container banner-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item, index) in bannerList" :key="index">
                        <img class="banner_img" :src="item.imageUrl" v-if="index == 0 || index == (item.length - 1)">
                        <img class="banner_img" v-lazy="item.imageUrl" alt="" srcset="" v-else>
                    </div>
                </div>
                <div class="swiper-pagination banner-pagination"></div>
            </div>
        </div> -->
        </div>
    </div>
</template>

<script>

import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
import { mapState, mapMutations } from "vuex";
export default {
    name: 'Header',
    data() {
        return {
            navList: [
                { name: '首页', url: '/HomeView', childList: [] },
                { name: '走进恒昌', url: '/AboutUs', childList: [{ name: '企业简介', url: '/AboutUs/companyIntroduction' }, { name: '人才招聘', url: '/AboutUs/talentRecruitment' }, { name: '联系我们', url: '/AboutUs/contractUs' }] },
                { name: '产品中心', url: '/product', childList: [] },
                { name: '技术实力', url: '/serviceSupport', childList: [{ name: '制造设备', url: '/serviceSupport/technicalStrength' }, { name: '加工设备', url: '/serviceSupport/technicalStrength' }, { name: '检测设备', url: '/serviceSupport/technicalStrength' }] },
                { name: '恒昌资讯', url: '/ronrunNews', childList: [{ name: '新闻中心', url: '/ronrunNews/newsCenter' }, { name: '企业视频', url: '/ronrunNews/brandVideo' }, { name: '招标信息', url: '/ronrunNews/ecology' }] },
                { name: '营销网络', url: '/engineering', childList: [{ name: '国际工程', url: '/engineering' }, { name: '国内工程', url: '/engineering' }] },
                { name: '企业愿景', url: '/joinInvestment', childList: [{ name: '发展目标', url: '/joinInvestment/brandStrength' }, { name: '近期目标', url: '/joinInvestment/brandStrength' }, { name: '中期目标', url: '/joinInvestment/brandStrength' }, { name: '远景目标', url: '/joinInvestment/brandStrength' }] }
            ],
            menuIndex: -1,
            isMenuShow: false,
            whether: false, //是否吸顶
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        ...mapMutations(['setAboutIndex', 'setJoinInvestmentIndex', 'setEngineeringIndex']),
        goUrl(val) { // 1.商城 2.经销商 3.会员
            let url = val == 1 ? 'https://www.baidu.com' : val == 2 ? '' : ''
            location.href = url  //直接跳转
            // window.open(url, '_blank');  //新窗口打开
        },
        handleScroll() {
            //计算滚动条位置
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            //计算绑定div位置
            var offsetTop = document.querySelector('#header').offsetTop;
            //进行比较设置位置fixed
            this.whether = scrollTop > offsetTop;
        },
        goIndex() {
            document.documentElement.scrollTop = 0
            this.isMenuShow = false
            this.$router.push('/HomeView')
        },
        menuClick(index) {
            document.documentElement.scrollTop = 0
            if (index == 0) {
                this.goIndex()
            }
            if (index == 2) {
                this.isMenuShow = false
                this.$router.push('/product')
            }
            this.menuIndex = this.menuIndex == index ? -1 : index
        },
        childClick(index, index2, url) {
            document.documentElement.scrollTop = 0
            if (index == 1) {
                this.setAboutIndex(index2)
            }
            if (index == 5) {
                this.setEngineeringIndex(index2)
            }
            if (index == 6) {
                this.setJoinInvestmentIndex(index2)
            }
            this.isMenuShow = false
            this.$router.push(`${url}?index=` + index2)
        },
        bannerClick(item) {
            if (item.isOpen == 1) {
                if (item.openType != 3) {
                    this.$router.push(item.openParam)
                } else {
                    location.href = item.openParam
                }
            }

        },
    }
}
</script>

<style lang="scss" scoped>
.isFixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;
    background: #fff;
}

.headerTool {
    background: #333333;
    height: 54px;

    .headerToolCon {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        padding-right: 26px;

        .item {
            display: flex;
            align-items: center;
            color: #fff;
            margin-left: 14px;
            font-size: 12px;
            cursor: pointer;

            img {
                margin-right: 10px;
                width: 24px;
                height: 24px;
            }
        }
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 20px;

    .logo {
        width: 148px;

        img {
            width: 148px;
            cursor: pointer;
        }
    }

    .menu_icon {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 40px;
        height: 40px;

        .line {
            position: relative;
            width: 30px;
            height: 4px;
            border-radius: 15px;
            background: #002d73;

            &::before {
                position: absolute;
                left: 0;
                top: -10px;
                content: "";
                width: 30px;
                height: 4px;
                border-radius: 15px;
                background: #002d73;
            }

            &::after {
                position: absolute;
                left: 0;
                bottom: -10px;
                content: "";
                width: 30px;
                height: 4px;
                border-radius: 15px;
                background: #002d73;
            }
        }
    }


}

.menu_box {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 99;

    .menu_content {
        width: 80%;
        height: 100%;
        background: #fff;
        padding: 20px 15px;
        overflow-y: scroll;

        .close {
            .el-icon-close {
                font-weight: 700;
                font-size: 26px;
                color: #002d73;
            }
        }

        .logo {
            text-align: center;
            margin-top: 5px;

            img {
                width: 160px;
            }
        }

        .menu {
            margin-top: 30px;

            .menuItem {
                padding: 10px 30px;
                cursor: pointer;
                font-size: 14px;
                font-weight: 700;

                .one {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .menuText {
                        display: flex;
                        align-items: center;
                    }

                    .el-icon-arrow-down {
                        font-weight: 700;
                    }
                }

                .oneCheck {
                    color: #002d73;
                }


            }

            .menuChild {
                z-index: 9;
                background: #fff;
                padding: 0 20px;
                max-height: 0;
                overflow: hidden;
                transition: all 1s ease-in-out;

                .menuChildItem {
                    padding: 11px 0;

                    &:hover {
                        color: #002d73;

                    }
                }
            }

            .menuChildShow {
                max-height: 1000px;
            }
        }
    }

    .showMenu {
        animation: menuShowA 0.7s ease-out forwards;
    }


}

.showBox {
    animation: menuShowAB 0.7s ease-out forwards;
}

@keyframes menuShowA {
    0% {
        transform: translateX(80%);
    }

    100% {
        transform: translateX(0);
    }
}


@keyframes menuShowAB {
    0% {
        background: rgba(0, 0, 0, .2);
    }

    100% {
        background: rgba(0, 0, 0, .9);
    }
}

.banner {
    // height: 774px;
    height: 574px;

    .banner_img {
        width: 100%;
        height: 574px;
        object-fit: cover;
    }

    .banner_video {
        width: 100%;
        height: 574px;
        background-color: #eee2d4;
        object-fit: cover;
    }
}
</style>
