import Vue from 'vue';
import VueRouter from 'vue-router';
import routerM from './routerM';
import routerPC from './routerPC';

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const ISMOBILE = function () {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
};

// 创建路由实例
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  fallback: false,
  // 这里判断后选择使用移动端的路由还是pc端的路由
  routes: ISMOBILE() ? routerM : routerPC,
});

export default router;