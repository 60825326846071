<template>
    <div>
        <div class="bannerCommon swiper_warp">
            <div class="swiper-container banner-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item, index) in bannerList" :key="index" @click="$bannerClick(item)">
                        <img class="bannerCommon_img" :src="item.imageUrl" v-if="index == 0 || index == (item.length - 1)">
                        <img class="bannerCommon_img" v-lazy="item.imageUrl" alt="" srcset="" v-else>
                    </div>
                </div>
                <div class="swiper-pagination banner-pagination"></div>
            </div>
        </div>
        <div class="tab">
            <div class="tab-list wBody">
                <div class="title" @click="$router.push('/AboutUs')">
                    企业愿景
                </div>
                <div class="list">
                    <ul>
                        <li v-for="(item, index) in tabList" :key="index" :class="{ 'isCheck': tabIndex == index }"
                            @click="tabClick(index, item)">
                            <router-link :to="item.url">
                                <span>{{ item.name }}</span>
                            </router-link>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
        <div class="otherLink">
            <router-view />
        </div>

    </div>
</template>
  
<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
import { getBanner } from "@/api/home";
import { mapState, mapMutations } from "vuex";
export default {
    name: 'joinInvestment',
    metaInfo: {
        title: '不锈钢全屋定制加盟品牌—融润家居官网',// 设置页面title
        meta: [{
            name: 'Keywords',
            content: '不锈钢橱柜、不锈钢家居定制加盟，不锈钢全屋定制，不锈钢整体橱柜定制，不锈钢整体橱柜加盟，不锈钢橱柜家居定制十大品牌'
        }, {
            name: 'Description',
            content: '融润家居创立于2000年，是中国较早进入不锈钢家居领域的优秀企业之一。集设计、研发、生产、销售不锈钢家居产品于一体的国家高新技术企业，一直以来，融润都在为家人的舞台定制美的、极致的、专属的生活空间和不锈钢家居产品，不锈钢家居定制加盟就选融润！'
        }]
    },
    data() {
        return {

            tabList: [{ name: '发展目标', url: '/joinInvestment/brandStrength' }, { name: '近期目标', url: '/joinInvestment/brandStrength' }, { name: '中期目标', url: '/joinInvestment/brandStrength' }, { name: '远景目标', url: '/joinInvestment/brandStrength' }],
            tabIndex: 0,   //选项卡下标
            bannerList: [],
            mySwiperBanner: null,  //顶部轮播图
        };
    },
    computed: mapState(['joinInvestmentIndex']),
    watch: {
        joinInvestmentIndex: function (nVal, oldVal) {
            this.tabIndex = nVal
        }

    },
    mounted() {
        let go = this.$route.query.index
        if (go || go == 0) {
            this.tabIndex = go
        }
        this.loadBanner();
    },
    methods: {
        ...mapMutations(['setJoinInvestmentIndex']),
        tabClick(index, item) {
            this.tabIndex = index
            this.setJoinInvestmentIndex(index)
        },
        // 加载轮播图
        loadBanner() {
            let that = this;
            getBanner({
                type: 7
            }).then((res) => {
                if (res.code + '' === '200') {
                    let list = res.data;
                    this.bannerList = list;
                    setTimeout(() => {
                        this.initSwiperBanner();
                    }, 100)
                }
            });
        },
        initSwiperBanner() {
            this.mySwiperBanner = new Swiper('.banner-container', {
                autoplay: false,
                height: 554,
                loop: true, // 循环模式选项
                loopAdditionalSlides: 4,
                // 如果需要分页器
                // pagination: {
                //     el: '.banner-pagination',
                //     clickable: true, // 分页器可以点击
                // },

                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.arrow-right',
                    prevEl: '.arrow-left',
                },
                on: {
                    resize: () => {
                        setTimeout(() => {
                            this.mySwiperBanner.update()
                        }, 100)
                    },
                },
            });

        },
    }
}
</script>
<style lang="scss" scoped>
.tab {
    height: 78px;
    background: #1e4c99;
    color: #fff;
    font-size: 15px;

    .tab-list {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        .list {
            height: 100%;

            ul {
                height: 100%;
                display: flex;
                align-items: center;

                li {
                    height: 100%;
                    width: 100px;
                    display: flex;
                    align-items: center;

                    a {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }
                }

                .isCheck {
                    color: rgba(255, 255, 255, .5);
                }
            }
        }
    }
}
</style>
  