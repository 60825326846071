<template>
  <div>
    <div class="bannerLineImg" v-for="item in bannerList" :key="item.id">
      <img class="bannerLineImg_img" :src="item.imageUrl" alt="" srcset="">
    </div>
  </div>
</template>

<script>


import { getBanner } from "@/api/home";
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
export default {
  name: 'HomeView',
  data() {
    return {
      bannerList: [],
      mySwiperBanner: null,  //顶部轮播图
    };
  },
  mounted() {
    // 首页轮播图
    this.loadBanner();

  },
  methods: {
    // 加载轮播图
    loadBanner() {
      let that = this;
      getBanner({
        type: 1
      }).then((res) => {
        if (res.code + '' === '200') {
          let list = res.data;
          this.bannerList = list;
          // setTimeout(() => {
          //   this.initSwiperBanner();
          // }, 100)
        }
      });
    },
    initSwiperBanner() {
      this.mySwiperBanner = new Swiper('.banner-container', {
        autoplay: true,
        height: 554,
        loop: true, // 循环模式选项
        loopAdditionalSlides: 4,
        // 如果需要分页器
        pagination: {
          el: '.banner-pagination',
          clickable: true, // 分页器可以点击
        },

        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.arrow-right',
          prevEl: '.arrow-left',
        },
        on: {
          resize: () => {
            setTimeout(() => {
              this.mySwiperBanner.update()
            }, 100)
          },
        },
      });

    },

  }
}
</script>
<style lang="scss" scoped>
.bannerLineImg {
  width: 100%;

  .bannerLineImg_img {
    width: 100%;
  }
}
</style>
